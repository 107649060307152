import React from "react";
import * as blogStyles from "./BlogContent.module.css";

const BlogContent = (props) => {
  return (
    <div
      className={blogStyles.blogContent}
      dangerouslySetInnerHTML={{ __html: props.content }}
    />
  );
};

export default BlogContent;
